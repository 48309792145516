import request from '@/utils/request'
// 登录接口
export function Login(data) {
    return request({
        url: '/api/fleet/login',
        method: 'post',
        data: data
    })
}
// 登出接口
export function LoginOut(data) {
    return request({
        url: '/api/fleet/logout',
        method: 'post',
        data: data
    })
}
// 修改密码接口
export function Edituserinfo(data) {
    return request({
        url: '/api/fleet/edituserinfo',
        method: 'post',
        data: data
    })
}
//公司信息查询
export function configindex(data) {
    return request({
        url: '/api/fleet/configindex',
        method: 'post',
        data: data
    })
}
//添加 修改 公司信息查询
export function editconfig(data) {
    return request({
        url: '/api/fleet/editconfig',
        method: 'post',
        data: data
    })
}