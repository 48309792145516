<template>
  <div>
    <el-tag :key="index" v-for="(tag, index) in tags" :closable="tag.name !== 'users'" :disable-transitions="false" @close="handleClose(tag, index)" @click="changeMenu(tag)" :effect="isActive(tag) ? 'dark' : 'plain'">
      {{ tag.label }}
    </el-tag>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tags: [],
    };
  },
  watch: {
    tags: {
      handler (newTags) {
        localStorage.setItem('tags', JSON.stringify(newTags));
      },
      deep: true,
    },
    $route (to) {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // 如果需要验证身份，则在异步组件加载完成后再添加标签
        const tag = { name: to.name, label: to.meta.title };
        About().then(() => {
          const isTagExist = this.tags.some((t) => t.name === tag.name);
          if (!isTagExist) {
            this.tags.push(tag);
          }
        });
      } else {
        // 否则直接添加标签
        const tag = { name: to.name, label: to.meta.title };
        const isTagExist = this.tags.some((t) => t.name === tag.name);
        if (!isTagExist) {
          this.tags.push(tag);
        }
      }
    },
  },
  mounted () {
    const tags = JSON.parse(localStorage.getItem('tags') || '[]');
    if (tags.length) {
      this.tags = tags;
    } else {
      this.tags.push({ path: '/order?type=1', name: 'order', label: '订单信息' });
    }
  },
  methods: {
    handleClose (tag, index) {
      // 处理关闭标签的逻辑
      if (tag.name !== 'order') {
        this.tags.splice(index, 1);
      }
      // 如果关闭的标签不是当前路由的话，就不跳转
      if (tag.name !== this.$route.name) {
        return;
      }
      const length = this.tags.length;
      // 关闭的标签是最右边的话，往左边跳转一个
      if (index === length) {
        this.$router.push({ name: this.tags[index - 1].name });
      } else {
        // 否则往右边跳转
        this.$router.push({ name: this.tags[index].name });
      }
    },
    changeMenu (tag) {
      // 跳转首页
      if (tag.name === 'order') {
        if (this.$route.path !== '/order?type=1') {
          this.$router.push({ path: '/order?type=1' });
        }
        return;
      }
      // 处理点击标签的逻辑
      if (tag.name !== 'order') {
        const toPath = this.$router.resolve({ name: tag.name }).href;
        if (this.$route.path !== toPath) {
          this.$router.push({ name: tag.name });
        }
      }
    },
    isActive (tag) {
      // 判断当前标签是否为当前路由
      return tag.name === this.$route.name;
    },
  },
};
</script>
<style>
.el-tag--dark,
.el-tag--plain {
  cursor: pointer;
  margin-left: 10px;
}
.el-tag:nth-child(2n) {
  cursor: pointer;
  margin-left: 10px;
}
</style>